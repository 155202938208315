body {
    margin: 0;
  }
  .wrapper {
    display: flex;
    height: 20vh;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .button {
    border: 1px transparent;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    color: #eeeeee;
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: 20px;
    padding: 8px 30px;
    text-align: center;
    text-decoration: none;
    margin-left: 20px;
    -webkit-animation: glowing 1300ms infinite;
    -moz-animation: glowing 1300ms infinite;
    -o-animation: glowing 1300ms infinite;
    animation: glowing 1300ms infinite;
  }
  @-webkit-keyframes glowing {
    0% {
      background-color: #86EFAC;
      -webkit-box-shadow: 0 0 3px #86EFAC;
    }
    50% {
      background-color: #86EFAC;
      -webkit-box-shadow: 0 0 15px #22C55E;
    }
    100% {
      background-color: #86EFAC;
      -webkit-box-shadow: 0 0 3px #86EFAC;
    }
  }
  @keyframes glowing {
    0% {
      background-color: #86EFAC;
      box-shadow: 0 0 3px #86EFAC;
    }
    50% {
      background-color: #22C55E;
      box-shadow: 0 0 15px #22C55E;
    }
    100% {
      background-color: #86EFAC;
      box-shadow: 0 0 3px #86EFAC;
    }
  }
  .svg-btn {
    display: block;
    width: 230px;
    height: 230px;
    margin-left: 10px;
  }
  svg {
    fill: blue;
    -webkit-animation: glowing-polygon 1300ms infinite;
    -moz-animation: glowing-polygon 1300ms infinite;
    -o-animation: glowing-polygon 1300ms infinite;
    animation: glowing-polygon 1300ms infinite;
  }
  @-webkit-keyframes glowing-polygon {
    0% {
      fill: #86EFAC;
      -webkit-filter: drop-shadow( 0 0 3px #86EFAC);
    }
    50% {
      fill: #22C55E;
      -webkit-filter: drop-shadow( 0 0 15px #22C55E);
    }
    100% {
      fill: #86EFAC;
      -webkit-filter: drop-shadow( 0 0 3px #86EFAC);
    }
  }
  @keyframes glowingPolygon {
    0% {
      fill: #86EFAC;
      filter: drop-shadow( 0 0 3px #86EFAC);
    }
    50% {
      fill: #22C55E;
      filter: drop-shadow( 0 0 15px #22C55E);
    }
    100% {
      fill: #86EFAC;
      filter: drop-shadow( 0 0 3px #86EFAC);
    }
  }